<template>
<div id="category">
  这是分类页面
</div>
</template>

<script>
export default {
  name: "Category"
}
</script>

<style scoped>
#category {
  background-color: antiquewhite;
  width: 100%;
  height: 100%;
}
</style>