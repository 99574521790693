<template>
  <div>
    <img
      class="notFound"
      src="https://cdn.jsdelivr.net/gh/Shen-Yu/cdn/img/404.jpg"
      alt="notFound"
    />
  </div>
</template>

<script>
export default {
  name: "NotFound",
  methods: {
    hideAside() {
      this.$emit("handleHide")
    },
  },
  created() {
    this.hideAside()
  },
};
</script>

<style scoped>
.notFound {
  height: 90vh;
  margin: 20px;
  border-radius: 30px;
}
</style>
