<template>
<div id="about" >
  <div :id="isPhone ? 'p_card' : 'card'">
    <div :id="isPhone ? 'p_left' : 'left'">
      <el-collapse-transition>
        <div v-show="isShow">
          <img src="https://cdn.jsdelivr.net/gh/cserwen/images@master/img/1630727538713.jpeg" alt="cserwen" :class="isPhone ? 'p_img' : 'img'">
        </div>
      </el-collapse-transition>
    </div>
    <div :id="isPhone ? 'p_info' : 'info'" v-show="isShow">
      <div class="item">姓名：{{ name.output }}</div>
      <div class="item">邮箱：{{ email.output }}</div>
      <div class="item">GitHub：<a href="https://github.com/cserwen" target="_black">{{ github.output }}</a></div>
      <div class="item">星座：{{ constellation.output }}</div>
      <div class="item">现状：{{ now.output }}</div>
      <div class="item">爱好：{{ hobby.output }}</div>
    </div>  
  </div>
</div>
</template>

<script>
import EasyTyper from 'easy-typer-js'
export default {
  name: "About",
  data() {
    return {
      isShow: false,
      isPnone: false,
      info: {
        name: "cserwen",
        email: "cserwen@163.com",
        github: "cserwen",
        constellation: "金牛座",
        now: "工作&攒钱",
        hobby: "电子游戏，比如CSGO；还有就是看动漫、小说"
      },
      name: {
        output: '',
        type: 'normal',
        isEnd: false,
        speed: 100,
        backSpeed: 40,
        sleep: 3000,
        singleBack: false,
        sentencePause: true
      },
      email: {
        output: '',
        type: 'normal',
        isEnd: false,
        speed: 100,
        backSpeed: 40,
        sleep: 3000,
        singleBack: false,
        sentencePause: true
      },
      github: {
        output: '',
        type: 'normal',
        isEnd: false,
        speed: 100,
        backSpeed: 40,
        sleep: 3000,
        singleBack: false,
        sentencePause: true
      },
      constellation: {
        output: '',
        type: 'normal',
        isEnd: false,
        speed: 100,
        backSpeed: 40,
        sleep: 3000,
        singleBack: false,
        sentencePause: true
      },
      hobby: {
        output: '',
        type: 'normal',
        isEnd: false,
        speed: 100,
        backSpeed: 40,
        sleep: 3000,
        singleBack: false,
        sentencePause: true
      },
      now: {
        output: '',
        type: 'normal',
        isEnd: false,
        speed: 100,
        backSpeed: 40,
        sleep: 3000,
        singleBack: false,
        sentencePause: true
      },
      timer: null
    }
  },
  methods: {
    showImg() {
      this.isShow = true;
    },
    printStr(type) {
      let typer;
      switch (type) {
        case "name":
          typer = new EasyTyper(this.name, this.info["name"])
          break;
        case "email":
          typer = new EasyTyper(this.email, this.info["email"])
          break;
        case "github":
          typer = new EasyTyper(this.github, this.info["github"])
          break;
        case "constellation":
          typer = new EasyTyper(this.constellation, this.info["constellation"])
          break;
        case "hobby":
          typer = new EasyTyper(this.hobby, this.info["hobby"])
          break;
        case "now":
          typer = new EasyTyper(this.now, this.info["now"])
          break;
        default:
          break;
      }
      typer.init()
    },
    printInfo() {
      const that = this;
      that.printStr("name")
      that.sleep(700).then(()=> {
        that.printStr("email")
      })
      that.sleep(2200).then(()=> {
        that.printStr("github")
      })
      that.sleep(2900).then(()=> {
        that.printStr("constellation")
      })
      that.sleep(3400).then(()=> {
        that.printStr("now")
      })
      that.sleep(3900).then(()=> {
        that.printStr("hobby")
        if(that.timer != null) {
          clearTimeout(that.timer)
        }
      })
    },
    sleep(ms) {
      return new Promise(resolve => 
        this.timer = setTimeout(resolve, ms)
    )
}
  },
  created() {
    this.isPhone = document.documentElement.clientWidth < 1000;
    setTimeout(this.showImg, 200);
  },
  mounted() {
    this.printInfo();
    window.onresize = () => {
        return (() => {
            this.isPhone = document.documentElement.clientWidth < 1000;
        })()
    }
  }
}
</script>

<style scoped>
#about {
  min-height: 75vh;
  text-align: center;
}

#card {
  margin: 9% 22%;
  height: 100%;
}

#p_card {
  margin: 30px 10px;
}

#left {
  float: left;
  width: 50%;
  height: 100%;
}

.img {
  width: 55%;
}

.p_img {
  width: 45%;
}

#info {
  font-weight: bolder;
  font-size: 20px;
  text-align: left;
  line-height: 50px;
  margin-right: 10px;
}

#p_info {
  font-weight: bolder;
  font-size: 15px;
  text-align: left;
  line-height: 35px;
  margin: 10px 50px;
}

.item a {
  text-decoration: none;
  color: black;
}

.item a:hover {
  text-decoration: underline;
  color: #3C8CE7;
}
</style>
